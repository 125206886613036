import get from 'lodash/get';
import { localStorage, sessionStorage } from 'dibs-browser-storage';
import serverVars from 'server-vars';
import { getCurrency } from 'dibs-intl/exports/units';
// don't import from dibs-currency-helpers which should be deprecated :
const currencies = ['USD', 'GBP', 'EUR', 'CAD', 'AUD', 'CHF', 'MXN', 'NOK', 'SEK', 'DKK'];
const UC = 'userCurrency';
function validCurrency(currency = '') {
    currency = currency.toUpperCase();
    const isValid = !!currency && currencies.includes(currency);
    return isValid ? currency : undefined;
}

/**
 * Explanation :
 * User's current currency preference is determined in the following order :
 * 1. forced currency from query param - ie. ?currency=USD
 * 2. user preference - set in your account preferences
 * 3. local storage currency - set in the footer
 * 4. session storage currency - set based on regional info / IP
 * 5. regional info currency - same as above, just fetched from identity
 *
 * IF forced currency is present: over-ride the current value of session
 * storage currency so that this value persists during the current session
 * IF the user preference currency is present: over-ride the local
 * storage currency so that the value persists after logout
 * IF the regional currency is present but local and session are not:
 * populate this regional currency value into the session storage
 */
function getCurrencyPreference({ user = {}, regionalCurrency = '' } = {}) {
    const queryParamCurrency = validCurrency(
        new URLSearchParams(window.location.search).get('currency') || ''
    );
    let forcedCurrency = validCurrency(serverVars.get('forcedCurrency'));
    forcedCurrency = forcedCurrency || queryParamCurrency;
    const userPreferenceCurrency = get(user, 'preferences.currency');
    const sessionCurrency = validCurrency(sessionStorage.getItem(UC));
    const localCurrency = validCurrency(localStorage.getItem(UC));
    regionalCurrency = validCurrency(regionalCurrency);
    // If forced currency is present then we want that value to persist for the current session
    // We do this b/c we assume the user would only get forced currency (from query param) if
    // we intended to show them that forced currency. We do the same for regional currency.
    // Also set the local store currency to the user's currency if that's present - that way if
    // the user logs out their currency preference will persist.
    if (forcedCurrency) {
        sessionStorage.setItem(UC, forcedCurrency);
        return forcedCurrency;
    } else if (userPreferenceCurrency) {
        localStorage.setItem(UC, userPreferenceCurrency);
        return userPreferenceCurrency;
    } else if (localCurrency || sessionCurrency) {
        return localCurrency || sessionCurrency;
    } else if (regionalCurrency) {
        sessionStorage.setItem(UC, regionalCurrency);
        return regionalCurrency;
    }

    const locale = serverVars.get('locale');
    return getCurrency(locale);
}

function getLocalCurrency() {
    return validCurrency(localStorage.getItem(UC));
}

function getSessionCurrency() {
    return validCurrency(sessionStorage.getItem(UC));
}

function setLocalCurrency(currency) {
    currency = validCurrency(currency);
    if (currency) {
        localStorage.setItem(UC, currency);
    } else {
        // eslint-disable-next-line no-console
        console.warn(`invalid local currency supplied ${currency}`);
    }
}

function setSessionCurrency(currency) {
    currency = validCurrency(currency);
    if (currency) {
        sessionStorage.setItem(UC, currency);
    } else {
        // eslint-disable-next-line no-console
        console.warn(`invalid session currency supplied ${currency}`);
    }
}

export {
    getCurrencyPreference,
    getSessionCurrency,
    getLocalCurrency,
    setLocalCurrency,
    setSessionCurrency,
    validCurrency,
};
