/**
 * @generated SignedSource<<c75086d76b0b74dfe01ebab3d0b97e63>>
 * @relayHash 6a8f7d1d87cc56034da88a6a0d5fbf91
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/505.0.0-2025-04-04T08:13:11.275Z/authorizeTokenQuery

import { ConcreteRequest } from 'relay-runtime';
export type authorizeTokenQuery$variables = {
  cookieDomain?: string | null;
};
export type authorizeTokenQuery$data = {
  readonly viewer: {
    readonly isUserTokenValid: boolean | null;
  };
};
export type authorizeTokenQuery = {
  response: authorizeTokenQuery$data;
  variables: authorizeTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "cookieDomain"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "cookieDomain",
      "variableName": "cookieDomain"
    },
    {
      "kind": "Literal",
      "name": "validateUserTypeCookie",
      "value": true
    }
  ],
  "kind": "ScalarField",
  "name": "isUserTokenValid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authorizeTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authorizeTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/505.0.0-2025-04-04T08:13:11.275Z/authorizeTokenQuery",
    "metadata": {},
    "name": "authorizeTokenQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "afa2bfc001705b503eb229d7019ee26f";

export default node;
